<div class="modal-header">
    <h4 class="modal-title">Selecciona la imágen</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="preview-image" *ngIf="
      imagePreview !== '' &&
      imagePreview
    ">
        <!-- <img [src]="imagePreview" class="img-preview" /> -->
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="16 / 9"
            format="jpeg"
            [imageQuality]=30
            (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    <div class="img-trigger">
        <i class="glyphicon glyphicon-picture"></i>
        <a class="text-decoration-underline add-img"
            onclick="document.getElementById('preview-img-form-getfile').click()" href="javascript:;">Agregar foto a la
            galería</a>
        <a class="text-decoration-underline change-img"
            onclick="document.getElementById('preview-img-form-getfile').click()" href="javascript:;">Elegír otra
            foto</a>
        <small>(Formato .jpg o .png)</small>
    </div>

    <input id="preview-img-form-getfile" type="file" class="form-control mb-3 visuallyhidden" accept=".jpg, .png"
        (change)="selectImage($event)">
    <span class="text-danger" role="alert" *ngIf="msjErrorImage">{{msjErrorImage}}</span>
    <div class="form-group">
        <label for="titulo">Titulo</label>
        <input type="text" class="form-control" [(ngModel)]="titulo" #title="ngModel" required
            placeholder="Título de imagen" maxlength="50">
        <span class="text-danger" role="alert" *ngIf="title.errors?.required && (title.dirty || title.touched)">El
            titulo es
            requerido</span>
    </div>
    <label for="descripcion">Descripción</label>
    <textarea class="form-control" cols="30" rows="5" [(ngModel)]="descripcion" placeholder="Descripción de imagen"
        maxlength="150"></textarea>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-block button-primary" (click)="close()"
        [disabled]="!imagePreview || msjErrorImage || !titulo || titulo===''">
        Confirmar
    </button>
</div>