import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  protected apiServer = AppConfigService.settings;
  show_imgs = this.apiServer.show_imgs;

  constructor() {}

  ngOnInit(): void {}
}
