import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { ERoles } from 'src/app/core/enums/roles';
import { Adm } from 'src/app/core/models/adm';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/http/auth.service';
import { StoreService } from 'src/app/core/services/store.service';
import { environment as env } from 'src/environments/environment';
import { isAdmin, isOfferer } from '../../functions/helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  protected apiServer = AppConfigService.settings;
  show_imgs = this.apiServer.show_imgs;
  user: User;
  state: string;
  admin: Adm;
  form: FormGroup;
  @ViewChild('logoutAdminForm') logoutAdminForm:ElementRef;
  @ViewChild('btnAdminLogout') btnAdminLogout:ElementRef;

  constructor(
    private storeService: StoreService,
    private authService: AuthService,
    private router: Router,
    private formBuilder:FormBuilder
  ) {
    this.user = this.storeService.getItem('user');
    this.state = this.storeService.getItem('state');
  }

  ngOnInit(): void {
    this.buildForm();

    this.storeService.watchStorage().subscribe(( res )=>{
      this.user = this.storeService.getItem('user');
      this.state = this.storeService.getItem('state');
    });

    this.router.events.subscribe((e) => {
      if ( e instanceof NavigationEnd ) 
      {
        if (e.url.startsWith('/admin')) 
        {
          if (isAdmin(this.user)) 
          {
            this.storeService.emitChanges();
          } else {
            this.storeService.removeItem('user');
          }
        }
      }
    });
  }

  onSubmit(form: any, e: any): void {  
    e.target.submit();    
  }

  buildForm():void{
    this.form = this.formBuilder.group({
      search:[
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(20)
        ]
      ]
    });
  }

  isAdmin()
  {
    return isAdmin(this.user);
  }

  isOfferer()
  {
    return isOfferer(this.user);
  }

  goToSearch(){
    if(this.form.valid){
      this.router.navigate(['/search'],{ queryParams : { query: this.form.value.search, fh: true } });
    }
  }

  goToMiBA() {
    window.location.href =
      this.apiServer.url_server + '/auth/redirect?url=' + window.location.href;
  }

  showAlert() {
    return (
      this.user &&
      !this.user.registroCompleto &&
      !this.router.url.startsWith('/user/register')
    );
  }

  logout() {
    this.authService.logout().subscribe((res:any) => {
      this.storeService.removeItem('user');
      this.storeService.removeItem('token');
      window.location.href = res.mibaLogout;
    });
  }

  logoutAdm() {
    /* 1- Request para anular el token de sesión en el backend */
    this.authService.logoutAdm().subscribe((res) => {
      /*2- Borrar datos del usuario en el localStorage */   
      this.storeService.removeItem('user');
      this.storeService.removeItem('token');
      this.storeService.removeItem('code');
      this.storeService.removeItem('adminCreatePublishment');
      this.storeService.removeItem('adminEditPublishment');
      this.storeService.removeItem('currentPublishment');
      /*3- Submit del formulario de cierre de sesión a ActiveDirectory */
      const 
      submitButton: HTMLElement = this.btnAdminLogout.nativeElement;
      submitButton.click();
    });
  }

  goToPublishmentCreate() {
    if (this.user) {
      if (this.user.registroCompleto) {
        if (this.user.perfilPublicacion_id) {
          this.router.navigate(['/publishment/create']);
        } else {
          this.router.navigate(['/publishment/new-profile']);
        }
      } else {
        this.router.navigate(['/user/register']);
      }
    } else {
      window.location.href =
        this.apiServer.url_server +
        '/auth/redirect?url=' +
        window.location.href;
      //window.location.href.replace('#', '%23');
    }
  }
}
