import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'locationFilter',
})
export class LocationFilterPipe implements PipeTransform {
  transform(locations: any[], field: string, value: string): any[] {
    let valueSelected = false;

    if (!locations) return [];

    locations.forEach((loc) => {
      if (loc.nombre == value) valueSelected = true;
    });

    if (valueSelected == false) {
      const locationsFiltered = locations.filter(
        (it) => it[field].toLowerCase().indexOf(value.toLowerCase()) != -1
      );

      //returns locations in alphabetical order
      return locationsFiltered.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
    }
  }
}
