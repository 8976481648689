<div class="carousel">
  <ngb-carousel *ngIf="imgs.length > 0">
    <ng-template *ngFor="let img of imgs" ngbSlide>
      <div class="picsum-img-wrapper">
        <i class="glyphicon glyphicon-trash" *ngIf="editable" (click)="deleteImg(img)"></i>
        <img [src]="img.src" alt="slide">
      </div>
      <div class="carousel-caption">
        <p class="carousel-title mb-2">{{ img.title }}</p>
        <p>{{ img.desc }}</p>
      </div>
    </ng-template>
  </ngb-carousel>
  <div *ngIf="imgs.length === 0">
    No hay imágenes
  </div>
</div>
