import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../../config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}

  getDownloadFile(endpoint:string)
  {
    return this.http
          .get( endpoint, { responseType: 'arraybuffer' });
  }

   downloadFile(res: any, type: string, name:string) 
   {
    let blob = new Blob([res], { type: type});
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
