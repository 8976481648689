import { AbstractControl, ValidatorFn } from '@angular/forms';

export function locationExists(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const locations = [
      'Constitución',
      'Montserrat',
      'Puerto Madero',
      'Retiro',
      'San Nicolás',
      'San Telmo',
      'Recoleta',
      'Balvanera',
      'San Cristóbal',
      'Barracas',
      'La Boca',
      'Nueva Pompeya',
      'Parque Patricios',
      'Almagro',
      'Boedo',
      'Caballito',
      'Flores',
      'Parque Chacabuco',
      'Villa Lugano',
      'Villa Riachuelo',
      'Villa Soldati',
      'Liniers',
      'Mataderos',
      'Parque Avellaneda',
      'Floresta',
      'Monte Castro',
      'Vélez Sarsfield',
      'Versalles',
      'Villa Luro',
      'Villa Real',
      'Villa Del Parque',
      'Villa Devoto',
      'Villa General Mitre',
      'Villa Santa Rita',
      'Coghlan',
      'Saavedra',
      'Villa Pueyrredón',
      'Villa Urquiza',
      'Belgrano',
      'Colegiales',
      'Nuñez',
      'Palermo',
      'Agronomía',
      'Chacarita',
      'Parque Chas',
      'La Paternal',
      'Villa Crespo',
      'Villa Ortúzar',
    ];

    if (control.value != '') {
      const locationIsCorrect = locations.some((l) => l === control.value);
      if (locationIsCorrect) return null;
      return { error: true };
    }
  };
}
