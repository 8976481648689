import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [SearchComponent, SearchFormComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule, NgxPaginationModule, NgbTooltipModule],
  exports: [SearchComponent, SearchFormComponent],
})
export class SearchModule {}
