import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { AppConfigService } from '../../config/app-config.service';
import { IMessage } from '../../models/asistencia';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}

  createMessage(body: FormData): Observable<IMessage> {
    return this.http.post<IMessage>(this.apiServer + '/pages/asistencia', body);
  }

  reportAbuse(body) {
    return this.http.post(this.apiServer + '/pages/report-abuse', body);
  }
}
