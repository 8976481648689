import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { AppConfigService } from '../../config/app-config.service';
import { IPagination } from '../../models/pagination';
import { IPublishment } from '../../models/publishment';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}

  search(body: HttpParams): Observable<IPublishment[]> {
    return this.http.get<IPublishment[]>(this.apiServer + '/pages/busqueda', {
      params: body,
    });
  }

  searchPublishmentByProfile(current_page:number,per_page:number,id:number): Observable<IPagination>
  {
    let params = new HttpParams()
    .set('id', id.toString())
    .set('page',current_page.toString())
    .set('per_page',per_page.toString());

    return this.http.get<IPagination>(this.apiServer + '/pages/busqueda/publishmentsByProfile', { params } );
  }
}
