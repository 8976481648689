import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../../environments/environment';
import { AppConfigService } from '../../config/app-config.service';
import { IInitialData } from '../../models/initial-data';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}

  getDataSearch(): Observable<IInitialData> {
    return this.http.get<IInitialData>(this.apiServer + '/pages/inicio');
  }

  loginTest(idUsuario): Observable<{ token: string; user: User }> {
    return this.http.get<{ token: string; user: User }>(
      this.apiServer + '/usuario/forzar-login/' + idUsuario
    );
  }

  conectar(body): Observable<any> {
    return this.http.post<any>(this.apiServer + '/pages/contacto', body);
  }
}
