import { PlatformLocation } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';


@Component({
  selector: 'app-image-select',
  templateUrl: './image-select.component.html',
  styleUrls: ['./image-select.component.scss'],
})
export class ImageSelectComponent implements OnInit {
  imagePreview: string;
  file: File;
  msjErrorImage: string;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  inputData;
  aspectRatio:number;

  constructor(
    private activeModal: NgbActiveModal,
    private platformLocation: PlatformLocation
  ) {
    this.platformLocation.onPopState(() => this.activeModal.dismiss());
  }

  ngOnInit(): void {
    this.aspectRatio = this.inputData?.aspectRatio || 16 / 9;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imagePreview = event.base64;
  }

  loadImageFailed() {
    this.msjErrorImage =
    'El formato del archivo no es soportado. La imagen debe ser JPG o PNG';
    this.file = null;
    this.imagePreview = null;
    return;
  }

  close() {
    const res = {
      fileCover: base64ToFile(this.imagePreview),
      imagePreview: this.imagePreview
    };
    this.activeModal.close(res);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  selectImage(event) {
    this.imageChangedEvent = event;
    this.file = (event.target as HTMLInputElement).files[0];

    this.imageValid(this.file);

    if (!this.msjErrorImage) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result.toString();
      };
      reader.readAsDataURL(this.file);
    }
  }

  imageValid(file: File) {
    this.msjErrorImage = null;
    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/jpg'
    ) {
      this.msjErrorImage =
        'El formato del archivo no es soportado. La imagen debe ser JPG o PNG';
      this.file = null;
      this.imagePreview = null;
      return;
    }

    if (file.size / 1024 / 1024 > 5) {
      this.msjErrorImage = 'La imagen no puede superar los 5MB';
      this.file = null;
      this.imagePreview = null;
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    let img = new Image();
    img.src = window.URL.createObjectURL(file);

    let self = this;

    reader.onload = () => {
      img.onload = function (rs) {
        const height = img.height;
        const width = img.width;

        if (height < 400 || width < 960) {
          self.msjErrorImage =
            'La imagen no cumple con las dimensiones mínimas requeridas (Ancho 960px Alto 400px).';
          self.file = null;
          self.imagePreview = null;
        }
      };
    };
  }
}
