import { Component, Input, OnChanges } from '@angular/core';

import {
  NgbCarousel,
  NgbCarouselConfig,
  NgbSlideEvent,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-slider-cards',
  templateUrl: './slider-cards.component.html',
  styleUrls: ['./slider-cards.component.scss'],
})
export class SliderCardsComponent implements OnChanges {
  @Input() items: any[];
  @Input() itemsForGroup: number;
  slides: any[] = [];

  ngbSlide: NgbCarousel;

  constructor(config: NgbCarouselConfig) {
    config.interval = 10000;
    config.wrap = true;
    config.pauseOnHover = true;
    config.animation = true;
  }

  ngOnChanges(): void {
    if (this.slides.length == 0) {
      this.generateSlides();
    }
  }

  generateSlides() {
    if (this.items) {
      let inicioSlider = 0;
      let finSlider = this.itemsForGroup;

      for (let i = 0; i < this.getQuantitySlides(); i++) {
        this.slides.push(this.getItems(inicioSlider, finSlider));
        inicioSlider += this.itemsForGroup;
        finSlider += this.itemsForGroup;
      }
    }
  }

  getQuantitySlides() {
    return Math.ceil(this.items.length / this.itemsForGroup);
  }

  onSlide(slideEvent: NgbSlideEvent) {
    /* after slide prev o next */
  }

  getItems(inicioSlider, finSlider) {
    return this.items.slice(inicioSlider, finSlider);
  }

  keyDownRedirect(tipoPublicacion_id: number, id: number) {
    window.location.href =
      '/search?tipoPublicacion_id=' + tipoPublicacion_id + '&rubro_id=' + id;
  }
}
