import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SliderComponent } from './slider/slider.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchModule } from '../search/search.module';
import { HomeRoutingModule } from './home-routing.module';
import { NgbCarouselModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [HomeComponent, SliderComponent],
  imports: [SharedModule, CommonModule, SearchModule, HomeRoutingModule, NgbCarouselModule, NgbTooltipModule],
})
export class HomeModule {}
