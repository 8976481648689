<footer class="mt-5" role="contentinfo">
  <div class="container-fluid pl-5 pr-5">
    <div class="row hidden-xs p-5">
      <div class="col-md-12 d-flex align-items-center footer-content mb-4">
        <div class="col-md-8 d-flex">
          <a href="https://www.buenosaires.gob.ar/desarrollohumanoyhabitat">
            <img class="footer-logo-ba" src="assets/images/logo-BApresente-vertical.svg" *ngIf="show_imgs"
              alt="Logo Buenos Aires Presente" />
          </a>
          <img class="footer-logo-valorPopular" src="assets/images/ba_logo.png" *ngIf="show_imgs"
            alt="Logo Valor Popular" />
        </div>
      </div>
      <!-- Support links -->
      <div class="col-md-4 d-flex footer-contact-numbers">
        <p>Teléfonos útiles</p>
        <a href="https://www.buenosaires.gob.ar/laciudad/telefonosutiles">Ver todos los teléfonos</a>
      </div>
      <div class="col-md-8 d-flex footer-group-numbers">
        <div class="footer-contact-col">
          <a href="tel:102">102</a>
          <p>Niñez y Adolescencia</p>
        </div>
        <div class="footer-contact-col">
          <a href="tel:103">103</a>
          <p>Emergencias</p>
        </div>
        <div class="footer-contact-col">
          <a href="tel:107">107</a>
          <p>SAME</p>
        </div>
        <div class="footer-contact-col">
          <a href="tel:108">108</a>
          <p>Buenos Aires Presente</p>
        </div>
        <div class="footer-contact-col">
          <a href="tel:911">911</a>
          <p>Policía</p>
        </div>
        <div class="footer-contact-col">
          <a href="tel:147">147</a>
          <p>Atención ciudadana</p>
        </div>
        <div class="footer-contact-col">
          <a href="tel:144">144</a>
          <p>Violencia de g&eacute;nero</p>
        </div>
      </div>

      <!-- Social links -->
      <div class="col-md-4 d-flex footer-social-links footer-contact-numbers mt-4">
        <p>Redes de la ciudad</p>
      </div>
      <div class="col-md-8 d-flex footer-social-links mt-4">
        <a href="https://www.facebook.com/GCBA/"><img class="footer-facebook"
            src="../../../../../assets/images/facebook_0.png" alt="Logo de Facebook"><span class="sr-only">Ir a
            Facebook</span></a>
        <a href="https://twitter.com/gcba"><img class="footer-tw" src="../../../../../assets/images/twitter_6.png"
            alt="Logo de Twitter"><span class="sr-only">Ir a Twitter</span></a>
        <a href="https://www.youtube.com/user/GCBA"><img class="footer-youtube"
            src="../../../../../assets/images/youtube_0.png" alt="Logo de YouTube"><span class="sr-only">Ir a
            YouTube</span></a>
        <a href="https://www.instagram.com/buenosaires/"><img class="footer-instagram"
            src="../../../../../assets/images/instagram_0.png" alt="Logo de Instagram"><span class="sr-only">Ir a
            Instagram</span></a>
        <a href="feed:https://www.buenosaires.gob.ar/rss/noticias"><img class="footer-rrss"
            src="../../../../../assets/images/rrss.png" alt="logo del feed de rrss"><span class="sr-only">News
            Feed</span></a>
      </div>
    </div>
    <!-- legal links -->
    <div class="d-flex p-5 row-logo-bac">
      <div class="footer-logo-col">
        <a href="https://www.buenosaires.gob.ar/">
          <img class="footer-logo-bac" src="assets/images/logo-bac.svg" *ngIf="show_imgs"
            alt="Ciudad de Buenos Aires" />
        </a>
        <p>Los contenidos de buenos aires.gob.ar están licenciados bajo Creative Commons Reconocimiento 2.5 Argentina
          License.</p>
      </div>
      <div class="footer-links-col">
        <a href="https://boletinoficial.buenosaires.gob.ar/">Boletín oficial</a>
        <a href="https://www.buenosaires.gob.ar/innovacion/ciudadinteligente/terminos-y-condiciones">Términos y
          condiciones</a>
        <a href="https://www.buenosaires.gob.ar/privacidad">Política de privacidad</a>
        <a href="https://www.buenosaires.gob.ar/oficiosjudiciales">Oficios judiciales</a>
      </div>
    </div>
  </div>
</footer>
