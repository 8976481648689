import { ERoles } from "src/app/core/enums/roles";
import { User } from "src/app/core/models/user";

export function isAdmin(user:User):boolean
{
    if(user){
        return user.role?.name == ERoles.administrator;
    }
  
    return false;
}

export function isOfferer(user:User):boolean
{
    if(user){
        return user.role?.name == ERoles.offerer;
    }
  
    return false;
}

export function isDemanding(user:User):boolean
{
    if(user){
        return user.role?.name == ERoles.demanding;

    }

    return false;
}