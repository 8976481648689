<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [animation]="true" [autohide]="true"
    [delay]="5000" (hide)="toastService.remove(toast)">
    <div class="d-flex justify-content-end" role="alert">
        <i class="glyphicon glyphicon-remove pointer" (click)="toastService.remove(toast)"></i>
    </div>
    <div id="toast-description">
        <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
            <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
        </ng-template>

        <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </div>

</ngb-toast>