<ngb-carousel (slide)="onSlide($event)" tabindex="0">
  <ng-template ngbSlide *ngFor="let slide of slides">
    <div class="wrapper mb-4">
      <div class="row">
        <div class="col-md-6 col-lg-3" *ngFor="let item of slide">
          <div class="card text-white home-tile" tabindex="0"
            (keydown.enter)="keyDownRedirect(item['tipoPublicacion_id'], item['id'])" [routerLink]="['/search']"
            [queryParams]="{ tipoPublicacion_id: item['tipoPublicacion_id'], rubro_id: item['id'] }">
            <img [src]="item.Imagen?.url" onerror="this.src='assets/images/card-image-overlay.jpg'"
              class="card-img-circle" alt="..." />
            <div class="card-img-overlay">
              <p class="card-title">{{ item.nombre | truncate:40}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>