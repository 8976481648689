import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { ISearchFilters } from '../models/search-filters';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  filters: ISearchFilters;
  publishmentId: number;
  private storageSub = new Subject<String>();

  constructor(private modalService: NgbModal) {}

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  emitChanges() {
    this.storageSub.next('changed');
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next('changed');
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.storageSub.next('changed');
  }

  getItem(key:string)
  {
    const item = localStorage.getItem(key);
    return JSON.parse(item);
  }

}
