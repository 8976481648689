import { ElementRef } from '@angular/core';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/http/auth.service';
import { StoreService } from './core/services/store.service';
declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'valor popular';

  constructor(
    private _elementRef: ElementRef,
    private router: Router,
    private authService: AuthService,
    private storeService: StoreService
    ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsId, {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }

  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute('ng-version');
    const user = this.storeService.getItem('user');
    if(user){
      this.authService.check().subscribe(( isLogged: boolean ) => {
        if(!isLogged)
        {
          this.storeService.removeItem('user');
          window.location.reload();
        }
      });
    }    
  }
}
