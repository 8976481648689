import { Highlightable } from '@angular/cdk/a11y';
import { HostBinding, Input, ElementRef } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-list-locations',
  templateUrl: './list-locations.component.html',
  styleUrls: ['./list-locations.component.scss'],
})
export class ListLocationsComponent implements Highlightable {
  @Input() location;
  @Input() disabled = false;
  private _isActive = false;

  @HostBinding('class.active') get isActive() {
    return this._isActive;
  }

  constructor(private element: ElementRef<HTMLLIElement>) {}

  setActiveStyles() {
    this._isActive = true;
    this.element.nativeElement.scrollIntoView({ block: 'nearest' });
  }

  setInactiveStyles() {
    this._isActive = false;
  }

  getLabel() {
    return this.location.name;
  }
}
