import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaService } from 'angular-recaptcha3';
import { SupportService } from 'src/app/core/services/http/support.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { whiteSpaceValid } from '../../validators/validatorWhitespaces';

@Component({
  selector: 'app-report-abuse',
  templateUrl: './report-abuse.component.html',
  styleUrls: ['./report-abuse.component.scss']
})
export class ReportAbuseComponent implements OnInit {

  form:FormGroup;
  publishmentUrl: string;
  publishmentId: string;

  constructor(
    private activeModal: NgbActiveModal,
    private platformLocation: PlatformLocation,
    private reCaptchaService: ReCaptchaService,
    private formBuilder: FormBuilder,
    private router: Router,
    private supportService: SupportService,
    private toastService: ToastService
  ) {
    this.platformLocation.onPopState(() => this.activeModal.dismiss());
  }

  ngOnInit(): void {
    this.publishmentUrl = this.router.url;
    this.publishmentId = this.router.url.split('publishment/').pop();
    this.buildForm();
  }
  
  buildForm(){
    this.form = this.formBuilder.group({
      comment: [
        '',
        [
          Validators.required,
          Validators.maxLength(1000),
          Validators.minLength(3),
          whiteSpaceValid()
        ]
      ]
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  onSubmit(){
    this.reCaptchaService.execute({ action: 'standard_form' }).then((token) => {
      const body = new FormData();
      body.append('comment', this.form.value.comment);
      body.append('publishmentUrl', this.publishmentUrl);
      body.append('publishmentId', this.publishmentId);

      this.supportService.reportAbuse(body).subscribe(() => {
        this.activeModal.close();
        this.toastService.show('Su reporte fue enviado exitosamente!', {
          classname: 'bg-success',
        });
        setTimeout(() => {
          this.toastService.removeAll();
        }, 5000);
      });
    });
  }
}
