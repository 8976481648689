import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: any, quentity: number): string {
        return value.length > quentity ? value.substring(0, quentity) + "..." : value;
    }
    
}