<div tabindex="0" aria-labelledby="modal-title">
  <div class="modal-header" *ngIf="modalTitle != null" style="text-align: center">
    <h4 class="modal-title" id="modal-title">
      <!-- <span class="glyphicon glyphicon-question-sign fs-3"></span> -->
      {{ modalTitle }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose(); activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>{{ modalMessage }}</p>
  </div>
  <div *ngIf="buttons && buttons.length" class="modal-footer d-flex" [ngClass]="{
      'justify-content-center': modalHasOneButton,
      'justify-content-between': !modalHasOneButton
    }">
    <div *ngFor="let btn of buttons">
      <button type="button" class="btn btn-{{ btn.type }}"
        (click)="modalSubmit(btn.action); activeModal.close('Close click')">
        {{ btn.label }}
      </button>
    </div>
  </div>
  <div *ngIf="footerTag" class="footer-tag">
    <span>{{ footerTag }}</span>
  </div>
</div>