import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../../config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class HolidayService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}


  getAll(): Observable<any> {
    return this.http.get<any>(
      this.apiServer + '/backoffice/holiday/allEnabled', {});
  }

  getPaginated(current_page:number, per_page:number): Observable<any> {

    const params = new HttpParams()
    .set('page', '' + current_page)
    .set('per_page', '' + per_page);

    return this.http.get<any>(
      this.apiServer + '/backoffice/holiday', { params })
      .pipe(map((res) => {
        return {
          current_page:res.current_page,
          data:res.data,
          total:res.total,
          per_page:res.per_page
        }}));
  }

  createHoliday(holiday): Observable<any> {
    return this.http.post<any>(
      this.apiServer + '/backoffice/holiday',
      holiday
    );
  }

  updateHoliday(holiday): Observable<any> {
    holiday.append('_method', 'put');
    return this.http.post<any>(
      this.apiServer + '/backoffice/holiday',
      holiday
    );
  }

  getById(id):Observable<any> { 
    return this.http.get(this.apiServer + '/backoffice/holiday/byId/'+id );
  }

  getPdfDownloadUrl(id:number){
    return this.apiServer+'/backoffice/holiday/downloadPdf/'+id;
  }

}
