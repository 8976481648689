import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPublishment } from 'src/app/core/models/publishment';
import { AppConfigService } from '../../config/app-config.service';
import { IPublishmentProfile } from '../../models/publishment-profile';
import { IPublishmentType } from '../../models/publishment-type';


@Injectable({
  providedIn: 'root',
})
export class PublishmentService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(
    private http: HttpClient
  ) {}

  getPublishmentTypes(): Observable<IPublishmentType[]> {
    return this.http
      .get<IPublishmentType[]>(this.apiServer + '/tipos-publicaciones')
      .pipe(map((res) => res['data']));
  }

  getPublicationsForRevision(current_page:number, publicationTypeId:string, per_page:number, enabled: string): Observable<any>{

    const params = new HttpParams()
    .set('page', '' + current_page)
    .set('per_page', '' + per_page)
    .set('publicationTypeId', publicationTypeId)
    .set('habilitado', enabled)

    return this.http.get<any>(this.apiServer + '/backoffice/revision', {
      params,
    })
    .pipe(map((res) => {
      return {
        current_page:res.current_page,
        data:res.data,
        total:res.total,
        per_page:res.per_page
      }}));
  }

  getPublication(): Observable<IPublishment[]> {
    return this.http.get<IPublishment[]>(this.apiServer + '/publicaciones');
  }

  getProfileById(id: number): Observable<IPublishmentProfile> {
    return this.http.get<IPublishmentProfile>(
      this.apiServer + '/pages/perfil/' + id
    );
  }

  getById(id: number): Observable<IPublishment> {
    return this.http.get<IPublishment>(
      this.apiServer + '/pages/publicacion/' + id
    );
  }

  getByHolidayTag(holidayId:string,currentPage:string){

    const params = new HttpParams()
    .set('holidayId',holidayId)
    .set('page',currentPage);

    return this.http.get(
      this.apiServer + '/pages/busqueda/byHolidayTag', { params }
    );
  }

  createPublishmentProfile(profile): Observable<IPublishmentProfile> {
    return this.http.post<IPublishmentProfile>(
      this.apiServer + '/pages/perfil',
      profile
    );
  }

  editPublishmentProfile(newProfile): Observable<IPublishmentProfile> {
    newProfile.append('_method', 'put');
    return this.http.post<IPublishmentProfile>(
      this.apiServer + '/pages/perfil',
      newProfile
    );
  }

  createPublishment(publishment): Observable<IPublishment> {
    return this.http.post<IPublishment>(
      this.apiServer + '/pages/publicacion',
      publishment
    );
  }

  editPublishment(publishment): Observable<IPublishment> {
    return this.http.put<IPublishment>(
      this.apiServer + '/pages/publicacion/' + publishment.id,
      publishment
    );
  }

  editPublishmentForm(id, publishment): Observable<IPublishment> {
    return this.http.post<IPublishment>(
      this.apiServer + '/pages/publicacion/' + id,
      publishment
    );
  }

  deletePublishment(id) {
    return this.http.delete(this.apiServer + '/pages/publicacion/' + id);
  }

  saveImageGallery(body): Observable<any> {
    return this.http.post<any>(this.apiServer + '/galeria-publicacion', body);
  }

  getDownloadLink(typeId:string, habilitado:string) {
    return this.apiServer +'/backoffice/revision/download?publicationTypeId='+typeId+'&habilitado='+habilitado;
  }
  
}
