<div class="yellowbar" *ngIf="!user">
  <div class="d-flex justify-content-end align-items-center">
    <button class="btn-miba btn btn-block button-primary" type="button" (click)="goToMiBA()">Ingresar a
      miBA</button>
  </div>
</div>
<header>
  <nav class="navbar navbar-expand-md navbar-light">
    <div class="logo">
      <a [routerLink]="['/home']"><img src="/assets/images/ba_logo.png" alt="Logo BA" *ngIf="show_imgs" /></a>
      <h1 class="mb-0">Valor BA</h1>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [formGroup]="form" class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav bg-light">
        <li class="nav-item" *ngIf="!isAdmin()">
          <a class="nav-link miba-mobile" href="javascript:;" (click)="goToMiBA()" [hidden]="user">Ingresar a miBA</a>
        </li>
        <li class="nav-item">
          <input (keydown.enter)="goToSearch()" formControlName="search" style="min-width:200px;" type="text" class="form-control" placeholder="Buscar...">
        </li>        
        <li class="nav-item">
          <a (click)="goToSearch()" [ngClass]="form.invalid? 'disabled': 'enabled'" class="search nav-link">Buscar</a>
        </li>
        <li class="nav-item nav-item-border nav-item-oferer" *ngIf="isOfferer()">
          <a class="nav-link pointer" (click)="goToPublishmentCreate()">Crear publicación</a>
        </li>
        <li class="nav-item dropdow nav-item-border" *ngIf="user">
          <div class="dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{(user) ? user.nombre + ' ' + ( user.apellido || '' ) : ''}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" [routerLink]="['/user/profile']" *ngIf="!isAdmin()">Perfil</a>
              <a class="dropdown-item" href="javascript:;" (click)="logout()" *ngIf="!isAdmin()">Cerrar sesión</a>
              <a (click)="logoutAdm()" class="dropdown-item" href="javascript:;" *ngIf="isAdmin()">
                Cerrar sesión
              </a>
              <form style="display:none;" *ngIf="isAdmin()" class="dropdown-item" #logoutAdminForm="ngForm" (ngSubmit)="onSubmit(logoutAdminForm, $event)"  [action]="user?.logoutUrl" method="post">
                <input type="hidden" name="authorize" value="0">
                <input type="hidden" name="logout" value="1">
                <button #btnAdminLogout style="padding:0;border: none;background: none;max-height: 20px;font-size: 1rem;" type="submit">Cerrar sesión Test</button>
              </form>
            </div>
          </div>
        </li>
      </ul>
    </div>
    
  </nav>
</header>
<div class="alert alert-danger mt-2 mb-0 rounded-0" role="alert" *ngIf="showAlert()">
  <i class="glyphicon glyphicon-exclamation-sign"></i>
  Detectamos que aún no tienes definido a que tipo de usuario representas. ¡Hace click <a class="alert-link-banner"
    [routerLink]="['/user/register']">acá</a> para completarlo!
</div>
