import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { IImage } from 'src/app/core/models/image';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  @Input() imgs: IImage[];
  @Input() editable: boolean;
  @Output() delete: EventEmitter<IImage> = new EventEmitter<IImage>();

  constructor(config: NgbCarouselConfig) {
    config.interval = 10000000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = false;
  }

  ngOnInit(): void {}

  deleteImg(img: IImage) {
    this.delete.emit(img);
  }
}
