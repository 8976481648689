import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IRubric } from '../../models/rubric';
import { environment as env } from '../../../../environments/environment';
import { AppConfigService } from '../../config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class RubricService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}

  getAll(): Observable<IRubric[]> {
    return this.http
      .get<IRubric[]>(this.apiServer + '/rubros')
      .pipe(map((res) => res['data']));
  }

  getByType(type: string): Observable<IRubric[]> {
    return this.http
      .get<IRubric[]>(this.apiServer + '/rubros')
      .pipe(
        map((rubrics: IRubric[]) =>
          rubrics['data'].filter((rubric) => rubric.tipoPublicacion_id == type)
        )
      );
  }

  getById(id: number): Observable<IRubric> {
    return this.http
      .get<IRubric>(this.apiServer + '/backoffice/rubros/' + id)
      .pipe(map((res) => res['data']));
  }

  postRubric(body: any): Observable<IRubric> {
    return this.http
      .post<IRubric>(this.apiServer + '/backoffice/rubros', body)
      .pipe(map((res) => res['data']));
  }

  editRubric(body: any, id: number): Observable<any> {
    return this.http
      .post<any>(this.apiServer + '/backoffice/rubros/' + id, body)
      .pipe(map((res) => res['data']));
  }

  deleteRubric(id: number) {
    return this.http
      .delete<IRubric>(this.apiServer + '/backoffice/rubros/' + id)
      .pipe(map((res) => res['data']));
  }
}
