import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { StoreService } from '../services/store.service';
import { Router } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { AppConfigService } from '../config/app-config.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(
    private toastService: ToastService,
    private storeService: StoreService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error) => {
        let errorMessage = '';
        if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          errorMessage = 'Error: ';
          if (error.status === 0 && error.error instanceof ProgressEvent) {
            errorMessage += ' controle su conexión';
          } else {
            // backend error
            for (let key in error.error.errors) {
              errorMessage += ` ${error.error.errors[key]}`;
            }
          }
        }

        if (error.status === 401) {
          this.storeService.removeItem('user');
          this.storeService.removeItem('token');
          window.location.href =
          error.error.redirectUrl + window.location.href;
        }

        if (error.status === 403 || error.status === 404) {
          this.router.navigate(['/home']);
        }

        const toast = this.toastService.show(errorMessage, {
          animation: true,
          delay: 5000,
        });

        setTimeout(() => {
          this.toastService.removeAll();
        }, 5000);

        return throwError(errorMessage);
      })
    );
  }
}
