<div class="container">
    <div *ngIf="holiday" class="row">
        <div class="col-md-12 pt-5 text-center">
            <h1>{{holiday.title}}</h1>
            <p style="font-size: 24px;">{{holiday.description}}</p>
        </div>
    </div>
    <div class="row pb-3">
      <div class="col-md-12 text-right">
        <a *ngIf="pagination?.total > 0" (click)="getByHolidayTag()" href="javascript:void(0)">
          <ng-template #tips>Descargar<br />Catálogo</ng-template>
          <img style="max-width: 18px;" tooltipClass="custom-tooltip-white" placement="left" [ngbTooltip]="tips" src="../../../assets/images/download.svg" />
        </a>
      </div>
    </div>
    <div class="row">
        <div *ngFor="let result of publishments
         | paginate: { 
                      id: 'searchPagination',
                      itemsPerPage: pagination.perPage, 
                      currentPage: pagination.currentPage ,
                      totalItems: pagination.total
                    }; 
                      let i = index" 
                      class="col-md-6 col-lg-3">
          <div class="card outline-card mb-3 bg-dark text-white" 
            (keydown.enter)="showPublishmentDetail(result)"
            (click)="showPublishmentDetail(result)" 
            tabindex="0" 
            id="tab_{{i}}">
            <img [src]="result.imagen_portada?.url"
              class="card-img" alt="..." />
            <div class="card-img-overlay">
              <p class="card-title">{{ result.titulo }}</p>
              <p class="card-text text-truncate">
                {{ result.descripcion }}
              </p>
            </div>
          </div>
        </div>
    </div>
    <div class="pagination-right">
    <pagination-controls
          id="searchPagination"
          class="pagination-custom-style"
          [nextLabel]="labels.nextLabel"
          [previousLabel]="labels.previousLabel" 
          (pageChange)="getPublishments($event)"></pagination-controls></div>
</div>
<app-help-button></app-help-button>
