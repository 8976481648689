import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  menuItems: { name: string; url: string }[];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumb(
      this.activatedRoute.snapshot.data['breadcrumb']
    );
  }

  private createBreadcrumb(items: { path: string; url: string }[]) {
    let breadcrumb = [];

    if (items) {
      items.forEach((item) => {
        const menuItem = {
          name: item.path,
          url: item.url ? this.checkUrlPublishment(item.url) : null,
        };
        breadcrumb.push(menuItem);
      });
    }

    return breadcrumb;
  }

  private checkUrlPublishment(url) {
    if (url[0] === '/publishment/:id') {
      url = ['/publishment', localStorage.getItem('publishmentId')];
    }
    return url;
  }
}
