import { PlatformLocation } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from './modal-button';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() modalType: string;
  @Input() modalTitle: string;
  @Input() modalMessage: string;
  @Input() buttons: Array<ModalButton>;
  @Input() closeBtnAction: () => {};
  @Input() footerTag: string;

  @Output() modalOutput: EventEmitter<any> = new EventEmitter();
  @Output() modalCloseOutput: EventEmitter<any> = new EventEmitter();

  public modalHasOneButton: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private platformLocation: PlatformLocation
  ) {
    this.platformLocation.onPopState(() => this.activeModal.dismiss());
  }

  ngOnInit() {
    if (this.buttons && this.buttons.length === 1) {
      this.modalHasOneButton = true;
    }
  }

  modalSubmit(action: () => void): void {
    this.modalOutput.emit(action);
  }

  modalClose() {
    this.modalCloseOutput.emit(this.closeBtnAction());
  }
}
