<app-slider></app-slider>
<div *ngIf="!user" class="row sec-anunciarme">
  <div class="col-md-12 text-center"> Quiero formar parte del catálogo <button class="btn button-primary" (click)="goToPublishmentCreate()">
    Anunciarme
  </button></div>
</div>
<div class="container justify-content-center pb-5 pt-5">
  <h2 class="text-center">¿Qué estás buscando?</h2>
  <p class="lead text-center">
    Ingresá el producto o servicio que necesitas, o el nombre de un emprendimiento específico para ver los resultados.
    Asegúrate de <strong>seleccionar una categoría de búsqueda</strong> de la lista
    antes de presionar <b>buscar</b>.
  </p>
  <app-search-form> </app-search-form>
</div>

<div class="container-fluid justify-content-center mb-5 mt-3 pt-4 pb-5 home-slider">
  <div class="container">
    <h2 class="text-center">Rubros</h2>
    <h3 class="mt-4 mb-3 pl-2">Productos</h3>
    <app-slider-cards [items]="getRubricsForType(enumPublishmentTypes.Producto)" [itemsForGroup]="itemsForGroup">
    </app-slider-cards>
    <h3 class="mt-3 mb-3 pl-2">Servicios</h3>
    <app-slider-cards [items]="getRubricsForType(enumPublishmentTypes.Servicio)" [itemsForGroup]="itemsForGroup">
    </app-slider-cards>
  </div>
</div>
<app-help-button></app-help-button>