import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../config/app-config.service';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}

  retrieve(body) {
    return this.http.post(this.apiServer + '/auth/retrieve', body);
  }

  retrieveAdm(body) {
    return this.http.post(this.apiServer + '/backoffice/auth/retrieve', body);
  }

  logout(user?:User) {
    return this.http.post(this.apiServer + '/auth/logout', user || {} );
  }

  logoutAdm() {
    return this.http.post(this.apiServer + '/backoffice/auth/logout', {});
  }

  registerOfferor(body) {
    return this.http.post(this.apiServer + '/pages/usuario/oferente', body);
  }

  registerRequester(body) {
    return this.http.post(this.apiServer + '/pages/usuario/demandante', body);
  }

  check():Observable<boolean> 
  {
    return this.http.get<boolean>(this.apiServer+'/auth/check');
  }
}
