import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { AppConfigService } from '../../config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(
    private http: HttpClient
    ) {}

  getByHolidayTagUrl(idHoliday:string) {
    return this.apiServer + '/catalogs/getByHoliday?holidayId='+idHoliday;
  }

  getByRubricSearchUrl(type, rubric, location, query) {
    return this.apiServer + '/catalogs/getByRubric?tipoPublicacion_id='+type+'&rubro_id='+rubric+'&query='+query+'&barrio_id='+location;
  }

  getByProfileUrl(idHoliday:number) {
    return this.apiServer + '/catalogs/getByProfile?id='+idHoliday;
  }

}
