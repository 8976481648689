<form role="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header text-center">
        <h4 class="modal-title w-100">Reporte de abuso de emprendimiento</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row px-5 text-center">
            <p class="w-100">
                Indique brevemente el motivo por el cual desea reportar este emprendimiento.
            </p>
            <div class="col-md-12 pt-3">
                <textarea 
                    class="form-control"
                    formControlName="comment" 
                    rows="3"
                    maxlength="1000"
                    placeholder="Comentario"></textarea>

                    <div role="alert" tabindex="0" *ngIf="
              form.controls.comment.errors?.required &&
                (form.controls.comment.dirty ||
                  form.controls.comment.touched)
              ">
              <span class="sr-only">Error:</span>
              <span  class="form-text text-danger">
              El mensaje es requerido
              </span>          
            </div>
            <div role="alert" tabindex="0" *ngIf="form.controls.comment.errors?.whitespace">
              <span class="sr-only">Error:</span>
              <span  class="form-text text-danger">
              El mensaje no puede contener solo espacios en blanco.
              </span>          
            </div>
          <div role="alert" tabindex="0" *ngIf="
          form.controls.comment.errors?.maxlength &&
            (form.controls.comment.dirty ||
              form.controls.comment.touched)
          ">
            <span class="sr-only">Error:</span>
            <span  class="form-text text-danger">
            El mensaje no puede exceder los 1000 caracteres.
          </span>          
        </div>
        <div role="alert" tabindex="0" *ngIf="
        form.controls.comment.errors?.minlength &&
          (form.controls.comment.dirty ||
            form.controls.comment.touched)
        ">
          <span class="sr-only">Error:</span>
          <span  class="form-text text-danger">
            El mensaje debe contener al menos 3 caracteres.
          </span>          
      </div>
            </div>
            <recaptcha [size]="'invisible'" aria-hidden="true"></recaptcha>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="dismiss()" class="btn button btn-secondary">
            Cancelar
          </button>
          <button type="submit" class="btn button button-primary pointer" #submit [ngClass]="!form.valid?'disabled':'enabled'">
            Confirmar 
          </button>
    </div>
</form>