<div class="modal-header">
    <h4 class="modal-title">Selecciona la portada</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="preview-image" *ngIf="
      imagePreview !== '' &&
      imagePreview
    ">
        <!-- <img [src]="imagePreview" class="img-preview" /> -->
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="aspectRatio"
            format="jpeg"
            [imageQuality]=30
            (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    

    <div class="img-trigger">
        <i class="glyphicon glyphicon-picture"></i>
        <a class="text-decoration-underline add-img" onclick="document.getElementById('preview-img-getfile').click()"
            href="javascript:;">Agregar foto de portada</a>
        <a class="text-decoration-underline change-img" onclick="document.getElementById('preview-img-getfile').click()"
            href="javascript:;">Elegír otra foto de portada</a>
        <small>(Formato .jpg o .png)</small>
    </div>
    <input id="preview-img-getfile" type="file" class="form-control-file visuallyhidden" accept=".jpg, .png"
        (change)="selectImage($event)">
    <span class="text-danger" role="alert" *ngIf="msjErrorImage">{{msjErrorImage}}</span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-block button-primary" [disabled]="msjErrorImage" (click)="close()">
        Confirmar
    </button>
</div>