<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Bases y condiciones de uso del Sitio “Valor BA”</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Las presentes constituyen las bases y condiciones para el uso de los servicios ofrecidos por el Ministerio de
        Desarrollo Humano y Hábitat de la Ciudad Autónoma de Buenos Aires dentro del sitio web <a
          href="https://valorpopular.buenosaires.gob.ar" target="blank">valorpopular.buenosaires.gob.ar</a> con el
        objeto de facilitar que las Unidades Productivas de la Economía Popular y Social de la Ciudad Autónoma de Buenos
        Aires, ofrezcan sus bienes, productos y/o servicios al público en general.</p>
      <strong>1. GLOSARIO</strong>
      <p>
        MDHyH: Ministerio de Desarrollo Humano y Hábitat de la Ciudad Autónoma de Buenos Aires. <br>
        SITIO: Página web de publicación de productos y servicios realizados por las unidades productivas de la economía
        social y popular de la Ciudad de Buenos Aires. <br>
        SERVICIOS: Servicios y contenidos relacionados con la búsqueda de bienes, productos y servicios de la Economía
        social y popular de la Ciudad de Buenos Aires. <br>
        PERSONA USUARIA: Aquellas personas humanas o jurídicas que naveguen y/o visiten el SITIO., contemplando en su
        definición a LA PERSONA USUARIA ACTIVA. <br>
        PERSONA USUARIA ACTIVA: Aquellas personas humanas o jurídicas que se han registrado para navegar con un usuario
        propio. <br>
        PERSONA USUARIA ACTIVA OFERENTE: Aquellas personas humanas o jurídicas incorporadas previamente en el <a
          href="https://www.buenosaires.gob.ar/tramites/inscripcion-al-registro-de-unidades-productivas-de-la-economia-popular-y-social-rupepys"
          target="blank">Registro de Unidades Productivas de la Economía Popular y Social</a>, que se han registrado en
        el SITIO con usuario propio y que pueden ofrecer bienes, productos y servicios. <br>
        PERSONA USUARIA ACTIVA DEMANDANTE: Aquellas personas humanas o jurídicas que se han registrado en el SITIO con
        usuario propio y que puede consultar los datos de contactos de la PERSONA USUARIA ACTIVA OFERENTE. <br>
        UNIDADES PRODUCTIVAS (UP) <sup>1</sup> Toda aquella unidad individual o colectiva que realice actividades de
        interés
        económico y social, produciendo bienes o servicios en busca de satisfacer sus necesidades, con miras a su
        inserción en el mercado formal laboral o comercial con baja escala de capitalización y productividad, cuyo
        principal activo es la fuerza de trabajo. Las Unidades Productivas puedan estar integradas por:
        <br>
        a.- Personas humanas inscriptas en el monotributo social, monotributo promovido o adheridos al Régimen
        Simplificado para Pequeños Contribuyentes hasta categoría D inclusive; <br>
        b.- Personas jurídicas que revistan la categoría de,cooperativa, mutual, asociación civil u otras formas
        asociativas que establezca Ia Autoridad de Aplicación, y se desempeñen en el ámbito de Ia Economia Social; <br>
        c.- Grupos asociativos: agrupamientos de personas humanas de dos (2) o más integrantes que desarrollen
        actividades económicas productivas similares o complementarias, con el objeto de producir o comercializar bienes
        o servicios en el ámbito de Ia Economía Social, y que hubieran sido reconocidos por Ia Autoridad de Aplicación,
        conforme lo establecido por la Ley N° 6376. <br>
        ECONOMÍA POPULAR Y SOCIAL(EPyS) <sup>2</sup>: “Conjunto de actividades de producción y reproducción que ejerce
        una población en situación de vulnerabilidad económica y/o social para generar las bases materiales de
        realización de sus necesidades, bajo un sistema de prácticas que organizan el proceso económico de producción,
        distribución, circulación, financiamiento y consumo. Esta organización se implementa mediante Unidades
        Productivas cuya labor propone la inserción en el mercado, logrando una sociedad más inclusiva”.
        <br>
        REGISTRO DE UNIDADES PRODUCTIVAS DE LA ECONOMÍA POPULAR Y SOCIAL (RUPEPyS): Es el Registro de las unidades
        productivas que desarrollan actividad autogestiva en el marco de la economía popular y social, en el ámbito de
        la Ciudad de Buenos Aires.
      </p>
      <strong>2. OBJETO</strong>
      <p>
        La creación del presente sitio web, tiene como objetivo principal facilitar el acceso a la búsqueda de bienes,
        productos y/o servicios, ofrecidos por las Unidades Productivas de la Economía Popular y Social de la Ciudad
        Autónoma de Buenos Aires, con el fin de fomentar la comercialización de su producción en el sector empresario y
        el público en general.
      </p>
      <strong>3. DESTINATARIAS</strong>
      <p>
        El presente SITIO es de uso público y se encuentra destinado a aquellas unidades productivas inscriptas
        previamente en el RUPEPyS que busquen ampliar la difusión de sus bienes, productos y/o servicios; y aquellas
        personas –sean empresas o público general- que deseen acceder a la oferta de bienes, productos y/o servicios de
        las unidades productivas de la Economía Popular y Social de la Ciudad Autónoma de Buenos Aires. <br>
        A los fines de utilizar los servicios expuestos, se deberán aceptar, sin condicionamiento alguno, todos y cada
        uno de los términos que se establecen en las presentes Bases y Condiciones, y las <a
          href="https://docs.google.com/document/d/1Ddhf63CBsbocxgsPwLkPse4FsM1ZMXveGzGb8-dO0Hw/edit?usp=sharing"
          target="blank">Politicas de Privacidad que
          las integran</a>, así como sus futuras modificaciones y documentos complementarios, obligándose a cumplirlos
        sin objeciones. Se deja expresamente establecido que estas tienen carácter obligatorio y vinculante.
      </p>
      <strong>4. TÉRMINOS DE USO</strong>
      <p>
        El acceso al SITIO y la creación gratuita y voluntaria de una cuenta usuaria por parte de las personas usuarias
        activas, implica el conocimiento y la aceptación expresa e inequívoca de las presentes Bases y Condiciones y la
        <a href="https://docs.google.com/document/d/1Ddhf63CBsbocxgsPwLkPse4FsM1ZMXveGzGb8-dO0Hw/edit?usp=sharing"
          target="blank">Política de Privacidad</a>. <br>
        El MDHyH se reserva el derecho, a su sola discreción, de actualizar, alterar, sustituir, modificar o efectuar
        cualquier otra acción que corresponda, en todo o en parte, en estas Bases y Condiciones, en cualquier momento.
        <br>
        Las modificaciones entrarán en vigencia desde el momento en que el MDHyH indique o, en su defecto, se entenderá
        que su aplicación es inmediata una vez publicadas. La navegación en el SITIO luego de dichas modificaciones, se
        interpretará como una aceptación implícita de las nuevas Bases y Condiciones.El MDHYH podrá comunicar la
        modificación de sus Bases y Condiciones a través del SITIO o del envío de un correo electrónico a los datos de
        contacto registrados. <br>
        Si la persona usuaria no acepta las modificaciones efectuadas, en todo o en parte, de estas Bases y Condiciones,
        el MDHyH podrá suspender o dar de baja la cuenta. <br>
        Por dicho motivo, es obligación de la persona usuaria leer con atención las Bases y Condiciones previo a su
        registro.
      </p>
      <strong>5. CONDICIONES DE ACCESO</strong>
      <strong>PÚBLICO EN GENERAL</strong>
      <p>
        La utilización del SITIO está abierta a todo público, no debiendo abonar costo alguno al MDHyH. Cualquier
        PERSONA USUARIA puede navegar en él.
      </p>
      <strong>PERSONA USUARIA ACTIVA</strong>
      <p>
        Aquellas PERSONAS USUARIAS ACTIVAS que tengan como interés interactuar en el sitio, deberán previamente
        loguearse en mi BA y luego acceder al registro en el SITIO <br>
        La Registración de la PERSONA USUARIA ACTIVA y su contraseña se harán a través de miBA. En tal sentido, la
        PERSONA USUARIA ACTIVA se compromete a seleccionar, usar y conservar su contraseña de forma diligente y a
        mantener las mismas en secreto y en protección respecto de terceros. <br>
        La PERSONA USUARIA ACTIVA, será responsable de mantener la confidencialidad de la contraseña y de la cuenta,
        siendo también única y absoluta responsable por todas las actividades que ocurran bajo su contraseña o cuenta.
        Asimismo, se compromete a: <br>

        (a) notificar inmediatamente a través del “formulario de contacto”, de cualquier uso no autorizado de su
        contraseña o cuenta o de cualquier otra violación de seguridad, sin que ellos genere responsabilidad alguna al
        MDHyH, y <br>

        (b) asegurarse de cerrar su sesión personal cada vez que finalice su uso. MDHyH, no será responsable por ninguna
        pérdida, daño o perjuicio que resulte como consecuencia del incumplimiento de estas disposiciones. <br>
        La inexactitud o falsedad de los datos personales que proporcione cualquiera de las PERSONAS USUARIAS, que
        naveguen o interactúen en el SITIO, podría causar la suspensión de los Servicios. Asimismo, el MDHyH podrá
        suspender o inhabilitar definitivamente a aquellas personas que violen estas Bases y Condiciones.
      </p>

      <strong>BAJA DE PERSONAS USUARIAS ACTIVAS</strong>
      <p>
        El MDHyH se reserva el derecho de suspender o dar de baja a cualquier PERSONA USUARIA ACTIVA que, a su exclusivo
        criterio, no cumpla con los estándares definidos en estas Bases y Condiciones o con las Políticas de Privacidad
        vigentes Sin que ello genere derecho a resarcimiento alguno.
      </p>

      <strong>OBLIGACIONES DE LA PERSONA USUARIA ACTIVA</strong>
      <p>
        La PERSONA USUARIA ACTIVA OFERENTE debe registrarse para publicar. <br>
        La PERSONA USUARIA ACTIVA OFERENTE reconoce y acepta que el/los productos, bienes y servicios que carga en el
        SITIO cumplen con los requisitos establecidos en las presentes Bases y Condiciones. <br>
        El uso de los contenidos y/o SERVICIOS ofrecidos por el SITIO será bajo el exclusivo riesgo y/o responsabilidad
        de la PERSONA USUARIA ACTIVA. <br>
        La PERSONA USUARIA ACTIVA se compromete a utilizar el SITIO, todo su contenido y SERVICIOS de conformidad con la
        ley, la moral, el orden público, las presentes Bases y condiciones de Uso, en su caso, le sean de aplicación.
        Asimismo, se compromete a hacer un uso adecuado de los SERVICIOS del SITIO y a no emplearlos para realizar
        actividades ilícitas o constitutivas de delito, que atenten contra los derechos de terceros y/o que infrinjan la
        regulación sobre propiedad intelectual e industrial, o cualesquiera otras normas del ordenamiento jurídico
        aplicable. <br>
        La PERSONA USUARIA ACTIVA deberá completar, según corresponda : <br>
        En caso de ser DEMANDANTE:
      </p>
      <ul>
        <li>Si representa o no a una empresa. </li>
        <li>Cuit y razón social (en caso de representar a una persona jurídica)</li>
        <li>Aceptar las presentes Bases y Condiciones</li>
      </ul>
      <p>
        En caso de ser OFERENTE: <br>
        Ser una unidad productiva inscripta previamente en el Registro de Unidades Productivas de la Economía Popular y
        Social (RUPEPyS)<sup>3</sup> y completar los siguientes datos:
      </p>
      <ul>
        <li>Número de registro de la economía popular</li>
        <li>Cuit de emprendimiento</li>
        <li>Aceptar las presentes Bases y Condiciones</li>
      </ul>
      <p>
        Como condición esencial para la utilización del servicio, las personas que interactúen en él, manifiestan en
        carácter de declaración jurada que poseen capacidad legal para contratar y que no poseen ningún impedimento para
        recibir u ofrecer servicios bajo las leyes de Argentina. <br>
        En cuanto a los datos personales proporcionado se obligan a: <br>
        (a) proveer información verdadera, correcta, actual y completa acerca de su persona en función de lo requerido
        en el formulario de registro , y <br>
        (b) mantener y actualizar en todo momento los Datos de Registro a fin de conservarlos veraces, correctos,
        actuales y completos. Si suministra información que es falsa, inexacta, desactualizada o incompleta, o si
        existen indicios razonables para sospechar que dicha información es falsa, inexacta, desactualizada o
        incompleta, miBA tendrá el derecho de suspender o cerrar su cuenta y negarle el uso presente o futuro del
        Servicio (o cualquier parte del mismo). Sin perjuicio de las acciones administrativas o judiciales que pudieran
        corresponder.
      </p>
      <strong>6. SITIO WEB</strong>
      <p>
        Las personas usuarias expresamente comprenden, aceptan y están de acuerdo en que: <br>
        El SITIO puede contener errores. MDHyH no será responsable por ningún error u omisión contenidos en el SITIO.
        <br>
        El MDHyH no será responsable por ningún daño o perjuicio, directo o indirecto, sin ningún tipo de limitación,
        daños producidos por la pérdida y/o deterioro de información y/o por el uso del SITIO. <br>
        La utilización de Internet y, por ende, del SITIO puede implicar la asunción de riesgos y/o potenciales daños al
        software y al hardware de la persona usuaria. Por tal motivo, el equipo terminal desde el cual acceda al SITIO
        la persona usuaria estaría en condiciones de resultar atacado y/o dañado por la acción de hackers, quienes
        podrían incluso acceder a la información contenida en el equipo terminal de la persona usuaria, extraerla,
        sustraerla y/o dañarla. Las personas usuarias renuncian a efectuar reclamos por estos motivos. <br>
        El intercambio de información a través de Internet tiene el riesgo de que la información pueda ser captada por
        un tercero. El SITIO no se hace responsable de las consecuencias que pudiera acarrear a la persona usuaria tal
        situación. <br>
        El SITIO no guarda obligación alguna de conservar información que se haya puesto a disposición de las personas
        usuarias, ni tampoco aquella que le pueda haber sido enviada a estos últimos. <br>
        El MDHyH no se hace responsable por la información que contengan las publicaciones, así como tampoco de su
        actualización y/o veracidad.
      </p>
      <p>
        El MDHYH podrá utilizar inteligencia artificial para evaluar automáticamente imágenes y textos ingresados al
        SITIO con el objeto de detectar, previo a la publicación, posibles publicaciones indebidas, que contradicen la
        moral y las buenas costumbres. <br>
        Las personas usuarias que accedan al SITIO, pueden informar al MDHyH, en caso de encontrar publicaciones
        indebidas, que contradicen la moral y las buenas costumbres, a través del “formulario de contacto” disponible en
        el SITIO. <br>
        El SITIO sólo podrá ser utilizado con fines lícitos, para acceder a información referida a los productos, bienes
        y servicios. <br>
        El MDHyH prohíbe específicamente cualquier utilización del SITIO para:
      </p>
      <ul>
        <li>Enunciar datos biográficos incompletos, falsos o inexactos.</li>
        <li>Registrar más de una cuenta correspondiente a una misma persona usuaria.</li>
        <li>Usar cualquier mecanismo para impedir o intentar impedir el adecuado funcionamiento de este SITIO o
          cualquier actividad que se esté realizando en el mismo.</li>
        <li>El uso o intento de uso de cualquier máquina, software, herramienta, agente u otro mecanismo para navegar o
          buscar en el SITIO que sean distintos a las herramientas de búsqueda que este provea.</li>
        <li>Intentar descifrar, descompilar u obtener el código fuente de cualquier programa de software del SITIO.</li>
      </ul>
      <p>
        Se deja constancia de que cuando una PERSONA USUARIA DEMANDANTE solicite contactar a una PERSONA USUARIA
        OFERENTE, se enviará a la casilla de mail denunciada por la PERSONA USUARIA OFERENTE un correo electrónico
        notificatorio, proporcionando los datos de la PERSONA USUARIA DEMANDANTE que solicitó los datos de contacto. La
        información proporcionada será: nombre de la PERSONA USUARIA OFERENTE, nombre de la empresa en caso de que
        corresponda, y correo electrónico.
      </p>
      <strong>PUBLICACIONES</strong>
      <p>
        Se podrán publicar productos, bienes y/o servicios. Las publicaciones no tendrán costo alguno siendo totalmente
        gratuitas y sin límites de publicación y extensión. <br>
        En caso de que la publicación contenga descripción, la misma debe ser acorde a lo descrito en cuanto a sus
        condiciones y características relevantes. <br>
        En caso de que la publicación contenga imágenes fotográficas, las mismas deben ser concordantes con el producto,
        bien y/o servicio ofrecido. <br>
        Artículos Prohibidos. Sólo podrán ser ingresados en las listas de producto, bien y/o servicio, aquellos cuya
        venta no se encuentre tácita o expresamente prohibida en las presentes bases y condiciones o por la Ley vigente,
        pudiendo en caso contrario, el MDHyH tomar todas las medidas administrativas y legales que estime corresponder.
        <br>
        El MDHyH no se hace responsable por los daños, perjuicios, injurias o cualquier publicación engañosa que
        incumpla con cualquiera de los puntos descriptos ut supra, así como tampoco del vicio o riesgo de la cosa o de
        la prestación del servicio, siendo la PERSONA USUARIA ACTIVA quien responderá por los daños ocasionados a la
        cosa con motivo o en ocasión del servicio. <br>
        Las precisiones formuladas en la publicidad o en anuncios, se tienen por incluidas y obligan a la PERSONA
        USUARIA ACTIVA en su carácter de oferente. <br>
        El oferente que realice la publicación, puede retirarla del sitio en el momento que lo desee.
      </p>
      <strong>7. LICENCIA SOBRE IMÁGENES, FOTOGRAFÍAS, MARCAS E INFORMACIÓN DE PRODUCTO: </strong>
      <p>
        La PERSONA USUARIA ACTIVA autoriza al MDHyH a utilizar, publicar, reproducir y/o adaptar las imágenes y
        fotografías incluidas en sus publicaciones, su nombre comercial, marcas, frases publicitarias, logos, diseños,
        dibujos, imágenes y todo otro signo distintivo que la identifique. Será obligación de la PERSONA USUARIA ACTIVA
        incluir en las publicaciones las imágenes, fotografías y Marca, así como la Información de Producto, de manera
        actualizada, incluyendo aquellas advertencias que sean requeridas por la legislación aplicable para la venta o
        publicidad de los productos y servicios . Conforme a lo anterior, el MDHyH podrá obtener las imágenes,
        fotografías, Marca e Información de Producto directamente de la PERSONA USUARIA ACTIVA, terceros autorizados por
        éste, o a través del sitio web de la PERSONA USUARIA ACTIVA. <br>
        En particular, la PERSONA USUARIA ACTIVA otorga al MDHyH una autorización gratuita, irrevocable, no exclusiva,
        internacional y sin límite temporal para usar, publicar, reproducir y/o adaptar las imágenes, fotografías, la
        Marca y la Información de Producto con el fin de ser usadas en todos los sitios y aplicaciones del MDHyH, redes
        sociales y/o en cualquier medio masivo y no masivo de comunicación, incluyendo sin limitación, plataformas y
        cualquier otro medio digital o físico que se considere oportuno. <br>
        La PERSONA USUARIA ACTIVA declara y garantiza que es titular o licenciatario de los derechos necesarios sobre
        las imágenes, fotografías contenidas en sus publicaciones, sobre las Marcas, así como sobre la Información de
        Producto, y que cuenta con los derechos y facultades necesarias para conceder la autorización detallada en esta
        cláusula, siendo responsable exclusivo por cualquier infracción a derechos de terceros o por las inconsistencias
        o inexactitud en la Información de Producto. <br>
        El MDHyH podrá eliminar la publicación de las imágenes y fotografías, e incluso del bien o servicio, si
        interpretara, a su exclusivo criterio, que la imagen no cumple con las presentes Bases y Condiciones.
      </p>
      <strong>8. RESPONSABILIDADES</strong>
      <p>
        El MDHyH establece que: <br>
        Sólo pone a disposición de las personas usuarias un espacio virtual que les permite ponerse en comunicación
        mediante la provisión del contacto de la UP con el fin de facilitar el acceso a la información referida. El
        MDHyH no tiene participación ni responsabilidad alguna en el proceso de negociación y perfeccionamiento del
        contrato definitivo entre las partes. <br>
        El MDHyH no se responsabiliza por el contenido de las publicaciones de las PERSONAS USUARIAS ACTIVAS, la
        veracidad y exactitud de la información que en el SITIO se publique, ni de los cambios que sobre la misma
        pudieran acontecer. <br>
        El MDHyH no posee ningún tipo de acuerdo comercial con ninguna unidad productiva. <br>
        El MDHyH se reserva la facultad de moderar las publicaciones si interpretara que la misma viola los presentes
        términos y condiciones o la ley vigente o la moral y buenas costumbres o por alguna denuncia de las personas
        usuarias. <br>
        Las denuncias se podrán realizar a través del “formulario de contacto”, sin que ello genere responsabilidad de
        acción alguna al MDHyH.
      </p>
      <strong>9. CONTROL Y ADMINISTRACIÓN DEL SITIO</strong>
      <p>
        Este SITIO es controlado y operado por el Ministerio de Desarrollo Humano y Hábitat del Gobierno de la Ciudad
        Autónoma de Buenos Aires. Todo el material en este SITIO, (como imágenes, ilustraciones, clips de audio, y clips
        de video, entre otros), se encuentran protegidos, sin limitación alguna, por derechos de propiedad intelectual,
        cuya titularidad pertenece al MDHyH, en virtud de lo mentado en el Artículo N° 04 inciso “c” de la <a
          href="servicios.infoleg.gob.ar/infolegInternet/anexos/40000-44999/42755/texact.htm" target="blank">Ley
          Nacional de Propiedad Intelectual N°11.723</a>, reglamentada por <a
          href="servicios.infoleg.gob.ar/infolegInternet/anexos/180000-184999/180781/norma.htm" target="blank">Decreto
          41223/1934</a>, toda vez que mediante la firma de las presentes bases y condiciones, LA PERSONA USUARIA, en su
        carácter de autor del material a difundir, otorga expresa autorización al MDHyH para su tratamiento. En
        consecuencia, el material de este SITIO no puede ser copiado, reproducido, republicado, cargado, exhibido,
        transmitido, o distribuido en forma alguna. El modificar los materiales o utilizar los materiales para cualquier
        propósito representa una violación de los derechos de autor y otros derechos de propiedad.
      </p>
      <strong>10. DURACIÓN Y TERMINACIÓN</strong>
      <p>
        La prestación del SERVICIO del SITIO tiene una duración indeterminada. Sin perjuicio de lo anterior, MDHyH está
        autorizado para dar por terminada o suspender la prestación del SERVICIO, del SITIO y/o de cualquiera de los
        contenidos en cualquier momento, sin responsabilidad alguna ni derecho a exigir ningún tipo de resarcimiento.
      </p>
      <strong>11. LEY APLICABLE Y TRIBUNAL COMPETENTE</strong>
      <p>
        Los presentes Términos de Uso y las normas que lo complementan se regirán en todos sus puntos por las leyes
        vigentes en las materias aplicables y, en particular, por la Ley N° 1.845 (Texto Consolidado por Ley N° 6.347),
        Decreto N° 1501/09, Ley N° 25.326 en conjunto con su Decreto Reglamentario N° 1558/2001 y las reglamentaciones
        que sus respectivas autoridades de aplicación dispongan. <br>
        Cualquier controversia derivada de su existencia, validez, interpretación, alcance o cumplimiento, será sometida
        a los Tribunales en lo Contencioso, Administrativo y Tributario de la Ciudad Autónoma de Buenos Aires.
      </p>
      <strong>POLÍTICAS DE PRIVACIDAD</strong>
      <p>
        Desde el Gobierno de la Ciudad Autónoma de Buenos Aires, entendemos a la protección de los datos personales como
        un pilar fundamental en la era digital. Por eso creemos que haciendo un uso responsable de la información
        personal, no sólo protegemos la privacidad de quienes nos confían sus datos, sino que les permitimos operar con
        seguridad y confianza en nuestro ecosistema. <br>
        Las presentes Políticas de Privacidad, aplican a los servicios ofrecidos por el Ministerio de Desarrollo Humano
        y Hábitat de la Ciudad Autónoma de Buenos Aires (en adelante MDHyH) dentro del sitio web
        valorpopular.buenosaires.gob.ar (en adelante el SITIO) con el objeto de facilitar el acceso a la búsqueda de
        bienes, productos y/o servicios, ofrecidos por las Unidades Productivas de la Economía Popular y Social de la
        Ciudad Autónoma de Buenos Aires y rigen en conjunto con sus respectivas Bases y Condiciones de Uso, las cuales
        se deben aceptar antes de completar el formulario de registración para ser PERSONA USUARIA ACTIVA. Caso
        contrario, LA PERSONA USUARIA ACTIVA no se tendrá la registración para navegar y /o hacer uso del SITIO. <br>
        Estas Políticas de Privacidad, y aquellas que surjan de las eventuales modificaciones y adaptaciones que se
        realicen en valorpopular.buenosaires.gob.ar, serán aplicables y se considerarán aceptadas por quien utilice el
        mismo. La violación o incumplimiento a cualquiera de las estipulaciones contenidas en estas Políticas y/o de la
        <a href="http://www2.cedom.gob.ar/es/legislacion/normas/leyes/ley1845.html" target="blank"></a> Ley de
        Protección de Datos Personales de la Ciudad Autónoma de Buenos Aires N° 1845 (texto consolidado según Ley N°
        6.347), complementada por la Ley N° 4.496 y de la Ley Nacional de Protección de Datos Personales N° 25.326, y
        su Decreto Reglamentario N° 1558/2001, además de las reglamentaciones que las respectivas autoridades de
        aplicación dispongan, dará derecho al Ministerio de Desarrollo Humano y Hábitat a suspender y/o dar de baja el
        registro de LA PERSONA USUARIA ACTIVA y/o impedirle que pueda continuar accediendo y/o utilizando el mismo.
      </p>
      <strong>NAVEGACIÓN Y UTILIZACIÓN DEL SITIO</strong>
      <strong>USO GENERAL SIN REGISTRO</strong>
      <p>
        Los datos personales que se recolectarán y serán susceptibles de posterior tratamiento cuando cualquier persona
        humana o jurídica ingrese al SITIO serán los siguientes: <br>
        Datos relativos a las personas: todos aquellos datos que se soliciten al momento de ingresar al SITIO y sean
        informados en los formularios de registro, en todas las formas de usabilidad.
      </p>
      <strong>USO GENERAL CON REGISTRO - PERSONAS USUARIAS ACTIVAS</strong>
      <p>
        EL SITIO es la herramienta de acceso a publicaciones de bienes, productos y servicios realizados por las
        unidades productivas de la economía social y popular de la Ciudad Autónoma de Buenos Aires, que serán
        consideradas PERSONAS USUARIAS ACTIVAS. <br>
        A la información que se recolecta, susceptible de tratamiento, referida en el punto anterior, en caso de ser
        PERSONAS USUARIAS ACTIVAS, se le suma la siguiente. <br>
        La información personal de LAS PERSONAS USUARIAS ACTIVAS que optan por registrarse para navegar con un usuario
        propio a través del cual pueden ofrecer o solicitar bienes, productos y servicios, puede contener campos
        obligatorios, a saber:
      </p>
      <ul>
        <li>Número de registro de la economía popular.</li>
        <li>Cuit de emprendimiento.</li>
        <li>Si representa o no a una empresa.</li>
        <li>Cuit y razón social (en caso de representar a una persona jurídica).</li>
        <li>Aceptación de las Bases y Condiciones.</li>
      </ul>
      <p>Los datos recolectados podrán ser utilizados para:</p>
      <ul>
        <li>Satisfacer y optimizar los servicios solicitados al GCABA.</li>
        <li> Otros servicios potenciales que se puedan agregar.</li>
        <li>Personalizar el contenido visto por las PERSONAS USUARIAS.</li>
        <li>Generar estadísticas.</li>
        <li>Mejorar la calidad y actualidad de los datos que manejan las diferentes áreas del GCBA.</li>
        <li>Cualquier otro que tenga como fin mejorar la experiencia de las PERSONAS USUARIAS.</li>
      </ul>
      <p>
        El MDHyH hará todo lo posible por garantizar la confidencialidad de la información personal brindada, y de
        aquellos otros datos que así lo requieran, intentando que terceros ajenos, no autorizados, no puedan acceder a
        ella. <br>
        La información personal no será vendida o transferida a otras organizaciones, y no se entregará a empresas de
        publicidad, con excepción de lo dispuesto en el artículo 10 de la Ley 1845, de la Ciudad Autónoma de Buenos
        Aires.
      </p>
      <strong>POLÍTICA DE COOKIES</strong>
      <p>
        Cookies e identificadores anónimos: es posible utilizar diferentes tecnologías para recoger y almacenar datos
        cuando la PERSONA USUARIA GENERAL acceda AL SITIO incluyendo el envío de una o varias cookies o de
        identificadores anónimos a su dispositivo. <br>
        La instalación, permanencia y existencia de las cookies en la computadora o dispositivo de las personas usuarias
        depende de su exclusiva voluntad y pueden ser eliminadas cuando así lo deseen. <br>
        En este sentido, en cualquier momento la persona usuaria puede eliminar las cookies almacenadas, configurar el
        navegador para que solicite aprobación antes de almacenar cookies o directamente evitar que se almacenen
        cookies. Este procedimiento se realiza de forma diferente en los distintos navegadores y es necesario realizarlo
        en cada navegador que se utilice. A continuación ponemos a disposición los diferentes procedimientos para
        manejar esta configuración en los navegadores más utilizados: <br>
        Desde Internet Explorer (en Windows) <br>
        http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies <br>
        Microsoft Edge <br>
        https://support.microsoft.com/es-ar/help/4027947/microsoft-edge-delete-cookies <br>
        Firefox <br>
        https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we <br>
        Google Chrome <br>
        http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647 <br>
        Opera <br>
        http://help.opera.com/Windows/11.50/es-ES/cookies.html <br>
        Safari (en Mac) <br>
        http://www.apple.com/legal/privacy/es/cookies/ <br>
        En caso de desactivar las cookies, es posible que no se pueda hacer uso de todas las funcionalidades que ofrece
        EL SITIO.
      </p>
      <strong>PROTECCIÓN DE DATOS PERSONALES</strong>
      <p>
        El MDHyH respeta la privacidad de las personas usuarias y de las personas usuarias activas y garantiza la
        protección de sus datos. El MDHyH no pedirá ni agregará ningún dato sensible sin el expreso consentimiento de la
        persona usuaria. <br>
        A través de la aceptación de la presente política de privacidad, el titular de los datos personales presta
        expreso consentimiento, conforme lo referido por la Ley de Protección de Datos Personales de la Ciudad Autónoma
        de Buenos Aires, N° 1.845, para que, el “responsable del archivo”, el “Encargado del Tratamiento” o el “Usuario
        de datos”, o terceras partes designadas, tengan acceso al archivo, registro, o base de datos en la que se
        encuentren almacenados, efectúen cesiones y realicen los tratamiento de datos que consideren corresponder. <br>
        El consentimiento podrá ser revocado por cualquier medio y en cualquier momento. Dicha revocación no tendrá
        efectos retroactivos. <br>
        El SITIO pondrá a disposición, todas las herramientas necesarias para que las personas titulares de datos puedan
        ejercer los derechos reconocidos en el art. 13 de la ley 1845 de CABA.
      </p>
      <strong>CONSENTIMIENTO</strong>
      <p>
        Cada vez que una PERSONA USUARIA ACTIVA se registre, acceda y/o utilice EL SITIO, estará prestando su
        consentimiento para que a través del mismo se acceda a sus datos para cumplir con sus funcionalidades.
        Asimismo, es de suma importancia destacar que, los datos de la PERSONA USUARIA ACTIVA no serán utilizados para
        ninguna otra actividad que no tenga relación a los servicios prestados por el MDHyH en general.
      </p>
      <strong>SEGURIDAD</strong>
      <p>Además de cumplir con la normativa vigente en materia de medidas de seguridad aplicables al tratamiento de
        datos
        personales y con los requisitos establecidos por la Ley N° 1.845 (Texto Consolidado por Ley N° 6.347),
        complementada por la Ley Nº 4.496, el Decreto Reglamentario N° 1501/09, Ley Nacional de Protección de Datos
        Personales N° 25.326, su Decreto Reglamentario N° 1558/2001 y las reglamentaciones que sus respectivas
        autoridades
        de aplicación dispongan, adicionalmente utilizaremos los estándares de la industria en materia de protección de
        la
        confidencialidad de la información. Sin embargo, el GCABA no se responsabiliza por interceptaciones ilegales a
        los
        dispositivos móviles de las personas usuarias, que puedan afectar la seguridad de la información almacenada en
        el
        mismo, por parte de terceros no autorizados. <br>
        En ese entendimiento, el MDHyH manifiesta que el presente SITIO cumple los estándares de seguridad emitidos por
        la
        AGENCIA DE SEGURIDAD INFORMÁTICA del GCABA.
      </p>
      <strong>ALMACENAMIENTO DE LOS DATOS</strong>
      <p>
        Los datos recopilados podrán ser almacenados en servidores on premise del GCABA o en la nube, pudiendo o no,
        encontrarse dentro de las regiones adecuadas mencionadas en la Disposición N° 60 de la Dirección Nacional de
        Protección de Datos Personales, de fecha 18 de noviembre de 2016.
      </p>
      <strong>PLAZO DE ALMACENAMIENTO</strong>
      <p>
        Almacenaremos la información personal durante el tiempo necesario para cumplir con el propósito para el que se
        han
        recopilado, los requisitos legales o reglamentarios en la materia, o durante el periodo de prescripción legal o
        contractual correspondiente.
        Una vez concluido el lapso, los datos serán eliminados o anonimizados de manera tal que no pueda ser
        individualizada ninguna persona.
      </p>
      <strong>DIVULGACIÓN E INTERCAMBIO DE INFORMACIÓN</strong>
      <p>
        Podremos revelar la información personal brindada por las personas usuarias en caso de ser requerido por las
        autoridades judiciales o exista cualquier otra exigencia legal. <br>
        En caso de que otras autoridades administrativas y judiciales en ejercicio de su competencia requieran
        información, aunque no exista una orden ni una citación ejecutiva o judicial al efecto, podremos transferir la
        información recabada con la finalidades de: (a) colaborar en la investigación y denunciar fraudes, piratería,
        violaciones de propiedad intelectual o industrial o cualquier otro ilícito, así como cualquier actividad o
        circunstancia que pudiera generarle responsabilidad legal al GCABA y/o a sus usuarios; (b) salvaguarda de un
        interés público, la procuración o administración de justicia, el reconocimiento, ejercicio o defensa de un
        derecho
        en un proceso judicial o administrativo, y/o la resolución de controversias; y (c) dar cumplimiento a alguna
        ley,
        reglamento o disposición legal aplicable, o a algún mandato de autoridad competente debidamente fundado y
        motivado.
      </p>
      <strong>USO DE LA INFORMACIÓN POR OTRAS PERSONAS USUARIAS</strong>
      <p>
        El MHDyH permite a las personas usuarias activas, acceso a aquellos datos que faciliten la interacción entre
        ellas, tales como: nombre; razón social; correo electrónico; otros datos de contacto e información de las
        UNIDADES PRODUCTIVAS. <br>
        El MDHyH no controla, almacena ni tiene acceso a los mensajes y contenidos enviados por los usuarios a través de
        medios diferentes al SITIO, por lo que no asume ninguna responsabilidad respecto a los daños y perjuicios que
        pudiera ocasionar su uso. Las negociaciones llevadas a cabo por las personas usuarias a través de dichos medios
        quedan bajo absoluta y exclusiva responsabilidad de las personas usuarias. <br>
        El MDHyH no es responsable del uso que cualquier persona usuaria pudiera hacer de la información publicada en el
        SITIO. Las personas usuarias aceptan que el MDHyH no será responsable de las pérdidas ni daños ocasionados que
        puedan surgir como resultado de las negociaciones o acuerdos entre usuarias, así como la frustración o
        incumplimiento de los mismos.
      </p>
      <strong>ENLACES DE TERCERAS PARTES</strong>
      <p>
        En este sitio pueden direccionar a las personas usuarias a otros sitios web de terceras partes que no están
        afiliadas con lo aquí contenido. No nos responsabilizamos de examinar o evaluar el contenido y no garantizamos
        ni
        tendremos ninguna obligación o responsabilidad por cualquier material de terceros o sitios web, o de cualquier
        material, productos o servicios de terceros. <br>
        El MDHyH no es responsable del daño o perjuicio relacionado con la adquisición, navegación, o utilización de
        bienes, servicios, recursos, contenidos, o cualquier otra transacción realizada en conexión con sitios web de
        terceros o de las PERSONAS USUARIAS ACTIVAS. <br>
        La persona usuaria debe revisar cuidadosamente las políticas y prácticas de terceros o de las PERSONAS USUARIAS
        ACTIVAS y asegurarse de entenderlas antes de navegar o participar en cualquier transacción. Quejas, reclamos,
        inquietudes o preguntas con respecto a productos de terceros o de las PERSONAS USUARIAS ACTIVAS, deben ser
        dirigidas a esas personas o a las autoridades en la materia. <br>
        El MDHyH no será responsable ni garantizará el cumplimiento de las obligaciones que hubiese asumido la persona
        usuaria en las diferentes aplicaciones que no sean parte de la plataforma aquí desarrollada o que surjan
        implícitamente, en relación a los pagos a efectuar y/o recibir, en cuanto a la prestación de los servicios
        publicados y/o cualquier otro tipo de responsabilidad que pueda surgir ligada a la utilización de las
        plataformas
        a las cuales se acceda a través del SITIO.
      </p>
      <strong>DERECHO DE RECTIFICACIÓN, ACTUALIZACIÓN Y/O SUPRESIÓN</strong>
      <p>
        En cualquier caso LA PERSONA USUARIA Y LA PERSONA USUARIA ACTIVA, podrá rectificar, actualizar sus datos o
        solicitar la supresión de los mismos, ingresando al portal del SITIO.
        La solicitud de supresión de datos acarrea la baja de las publicaciones efectuadas, así como también el cierre
        de
        la cuenta de registro.
      </p>
      <strong>BAJA DE REGISTRO</strong>
      <p>
        El MDHyH podrá eliminar la publicación de las imágenes, grabaciones, audios, fotografías entre otras, , e
        incluso
        del bien, producto o servicio, si interpretara, a su exclusivo criterio, que no cumple con las Bases y
        Condiciones, incumple alguna normativa, viola la moral y/o buenas costumbres, así como también la baja de la
        cuenta de registro correspondiente. <br>
        La eliminación de la cuenta no incluirá la supresión de los datos que se hubieran generado en la utilización de
        los portales web y/o aplicaciones móviles del MDHyH integrados al SITIO.
      </p>
      <strong>RESERVA DE CANCELACIÓN DE UNA CUENTA</strong>
      <p>
        El MDHyH, en su rol de Administrador del SITIO, se reserva todos los derechos atinentes al otorgamiento,
        mantenimiento y/o cancelación de todas las cuentas de las PERSONAS USUARIAS ACTIVAS y de las personas en
        general.
        En consecuencia podrá eliminar cuentas por hechos que sean contrarios a la normativa vigente, considerados
        inmorales y/o por casos de robo de identidad, entre otros.
      </p>
      <strong>CAMBIOS EN LA POLÍTICA DE PRIVACIDAD</strong>
      <p>
        El MDHyH podrá modificar esta Política de Privacidad, de manera que recomendamos revisar periódicamente para
        conocer cuál es el uso que se hace de los datos personales y sus alcances. <br>
        Si las modificaciones que eventualmente se realizarán a esta Política de Privacidad pudieran afectar derechos
        que
        la normativa aplicable le reconoce a los titulares de los datos personales, los cambios serán notificados para
        requerir consentimiento expreso.
      </p>
      <strong>VIGENCIA</strong>
      <p>
        La validez de estas Políticas de Privacidad comenzarán a regir en el momento de su aceptación y no estará sujeto
        a
        plazo. El mismo finalizará cuando el MDHyH disponga o en su caso, se podrá discontinuar la utilización del SITIO
        y/o dejar sin efecto las prestaciones del mismo.
        La decisión de discontinuar SITIO en ningún caso generará derecho indemnizatorio alguno en favor de cualquier
        persona.
      </p>
      <strong>LEGISLACIÓN Y JURISDICCIÓN APLICABLE</strong>
      <p>
        La presente Política de Privacidad se regirá en todos sus puntos por las leyes vigentes en las materias
        aplicables
        y, en particular, por la Ley N° 1.845 (Texto Consolidado por Ley N° 6.347), Decreto N° 1501/09, Ley N° 25.326 en
        conjunto con su Decreto Reglamentario N° 1558/2001 y las reglamentaciones que sus respectivas autoridades de
        aplicación dispongan. <br>
        Cualquier controversia derivada de su existencia, validez, interpretación, alcance o cumplimiento, será sometida
        a
        los Tribunales en lo Contencioso, Administrativo y Tributario de la Ciudad Autónoma de Buenos Aires.
      </p>
    </div>
    <div class="modal-body" style="border-top: 1px solid #dbdbdb;">
      <small><sup>1</sup> Definición extraída de la Ley 6.376 de Promoción de la Economía Social de la Ciudad Autónoma
        de Buenos Aires.
      </small> <br>
      <small><sup>2</sup> Definición extraída de la Ley 6.376 de Promoción de la Economía Social de la Ciudad Autónoma
        de Buenos Aires.</small> <br>
      <small><sup>3</sup> Para más información ver: <a
          href="https://www.buenosaires.gob.ar/desarrollohumanoyhabitat/rupepys"
          target="blank">https://www.buenosaires.gob.ar/desarrollohumanoyhabitat/rupepys</a>
      </small>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
    </div>
  </div>
</div>
