import { AbstractControl, ValidatorFn } from '@angular/forms';

export function whiteSpaceValid(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    if(control.value != '')
    {
        const isWhitespace  = ( control.value || '' ).trim().length === 0;
        return isWhitespace ? { 'whitespace': true }  : null;
    }
    
    return null;
  };
}