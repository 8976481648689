import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IHoliday } from 'src/app/core/models/holiday';
import { User } from 'src/app/core/models/user';
import { StoreService } from 'src/app/core/services/store.service';
import { environment as env } from 'src/environments/environment';
import { HolidayService } from 'src/app/core/services/http/holiday.service';
import { AppConfigService } from 'src/app/core/config/app-config.service';
import { DownloadService } from 'src/app/core/services/http/download.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  protected apiServer = AppConfigService.settings.url_server;
  user: User;
  imgBanner: string = '/assets/images/banner-desktop.png';
  holidays: IHoliday;
  pdfUrl: string;

  constructor(
    private router: Router, 
    private storeService: StoreService,
    private _holidayService: HolidayService,
    private _downloadService: DownloadService ) {}

  ngOnInit(): void {
    this.getHolidays();
    this.setImgBanner();
  }

  getHolidays(){
    this._holidayService.getAll().subscribe((res) => {
      this.holidays = res;
    });
  }

  private setImgBanner() {
    const isMobile = window.matchMedia(
      'only screen and (max-width: 480px)'
    ).matches;
    if (isMobile) {
      this.imgBanner = '/assets/images/banner-mobile.png';
    }
  }

  goToPublishmentCreate() {
    if (this.user) {
      if (this.user.registroCompleto) {
        if (this.user.perfilPublicacion_id) {
          this.router.navigate(['/publishment/create']);
        } else {
          this.router.navigate(['/publishment/new-profile']);
        }
      } else {
        this.router.navigate(['/user/register']);
      }
    } else {
      window.location.href =
        this.apiServer + '/auth/redirect?url=' + window.location.href;
      //window.location.href.replace('#', '%23');
    }
  }

  async getHolidayPdf( id:number, title:string )
  {
    const url = this._holidayService.getPdfDownloadUrl(id);
    const file = await this._downloadService.getDownloadFile(url).toPromise();
    if(file){
      this._downloadService.downloadFile(file,"application/pdf",'Efeméride '+title+'.pdf');
    }
  }

}
